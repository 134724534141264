/* eslint-disable react/prop-types */
import React from 'react';
import PaymentMethods from '../PaymentMethods';
import Items from '../biplace/Items';

export default function TandemSection({
  cart,
  items,
  AddOrDeleteOrderItem,
  isPILoading,
}) {
  return (
    <>
      <div className="relative flex flex-col justify-center items-center bg-white w-full h-full px-3 lg:px-6 overflow-hidden text-gray-500">
        {/* Text */}
        <h1 className="text-xl lg:text-4xl font-playfair uppercase mb-3 text-center">
          Réservez votre vol en parapente
        </h1>
        {/* Logos */}
        <div className="flex justify-center items-center w-full gap-4 mb-6">
          <a
            href="https://www.qualite-tourisme.gouv.fr/fr"
            className="w-16 lg:w-[65px] h-13 lg:h-auto bg-transparent rounded-xl p-2"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="certification qualité tourisme"
              src="/image/certifications/logo-qnhd.svg"
            />
          </a>
          <a
            href="https://www.reunion.fr/organisez/conseils-de-voyage/labels-a-la-reunion/qualite-tourisme-ile-de-la-reunion/"
            className="w-24 lg:w-[115px] h-19 lg:h-auto bg-transparent rounded-xl p-2"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="certification qualité réunion tourisme"
              src="/image/certifications/qrt-hd.svg"
            />
          </a>
        </div>
        {/* Intro */}
        <h2 className="flex justify-center items-center mb-3 w-full lg:w-2/3 2xl:w-1/2 text-xs md:text-sm xl:text-base text-center text-balance">
          Volez en toute s&eacute;curit&eacute; avec une &eacute;quipe de professionnels
          sur l&apos;&icirc;le de la R&eacute;union
          &agrave; Saint-leu
        </h2>
        <h3 className="flex justify-center items-center font-light mb-3 w-full lg:w-2/3 2xl:w-1/2 text-xs md:text-sm xl:text-base text-center text-balance">
          Prenez de la hauteur au-dessus des for&ecirc;ts, ravines et pour finir en beaut&eacute;
          le magnifique lagon de Saint-Leu d&apos;o&ugrave; vous pourrez facilement observer les
          tortues, raies, dauphins ou encore les baleines
          pendant l&apos;hiver austral.
        </h3>
        {/* Languages */}
        <div className="flex justify-center items-center font-light mb-8 lg:mb-12 w-full text-xs lg:text-sm text-center">
          Nous parlons
          <div className="flex justify-center items-center ml-1">
            Français
            <img
              src="/image/flags/drapeau-site-france.svg"
              alt="nous parlons français"
              className="w-[30px] ml-1"
              loading="lazy"
            />
          </div>
          <div className="flex justify-center items-center ml-1">
            Créole
            <img
              src="/image/flags/drapeau-site-reunion.svg"
              alt="nous parlons le créole"
              className="w-[25px] ml-1"
              loading="lazy"
            />
          </div>
          <div className="flex justify-center items-center ml-1">
            et Anglais
            <img
              src="/image/flags/drapeau-site-usa.svg"
              alt="nous parlons l'anglais"
              className="w-[30px] ml-1"
              loading="lazy"
            />
          </div>
        </div>
        {/* Images */}
        <div className="hidden lg:block absolute lg:top-[280px] 2xl:top-[250px] lg:left-[170px] 2xl:left-[215px] w-auto lg:h-[130px] 2xl:h-[175px] group overflow-hidden rounded-lg shadow-lg z-10">
          <img
            alt="vol ou saut en parapente (tandem) 1"
            className="object-cover w-full h-full group-hover:scale-125 transition-all duration-[4s]"
            src="/image/homeImages/passenger_1_small.webp"
            loading="lazy"
          />
        </div>
        <div className="hidden lg:block absolute lg:top-[350px] 2xl:top-[360px] lg:left-[5px] 2xl:left-[20px] w-auto lg:h-[120px] 2xl:h-[155px] group overflow-hidden rounded-lg shadow-xl z-0">
          <img
            alt="volez et observez un baleine"
            className="object-cover w-full h-full group-hover:scale-125 transition-all duration-[4s]"
            src="/image/homeImages/whales_reunion_island_small.webp"
            loading="lazy"
          />
        </div>
        <div className="hidden lg:block absolute lg:top-[200px] 2xl:top-[150px] lg:left-[10px] 2xl:left-[35px] w-auto lg:h-[120px] 2xl:h-[160px] group overflow-hidden rounded-lg shadow-xl z-20">
          <img
            alt="vol ou saut en parapente (tandem) 1"
            className="object-cover w-full h-full group-hover:scale-125 transition-all duration-[4s]"
            src="/image/homeImages/turtle_kelonia_small.webp"
            loading="lazy"
          />
        </div>
        <h2 className="w-full lg:w-6/12 2xl:w-4/12 text-xl 2xl:text-3xl font-playfair font-light text-center bg-clip-text text-transparent bg-gradient-to-tl from-gray-500 from-10% via-gray-400 via-40% to-gray-500 to-90% mt-10 lg:mt-32 2xl:mt-20 animate-text-bg-gradient">
          Réservez en ligne en toute sérénité et
          profitez de nos nombreux avantages
          <br />
          Pour une expérience haut de gamme
        </h2>
        <div className="relative flex flex-wrap justify-center lg:justify-center items-center lg:items-stretch gap-4 w-full h-full text-gray-500 my-24">
          <div className="flex flex-col justify-between items-center mx-3 lg:mx-0 py-3 w-full lg:w-2/3 h-24 lg:h-28 lg:w-72 lg:min-w-72 lg:max-w-76 bg-white shadow-lg rounded-lg border">
            <div className="mb-2">
              <img
                src="/image/icons/icon-gopro.svg"
                alt="videos et photos offert !"
                className="w-[20px] lg:w-[30px]"
              />
            </div>
            <h2 className="lg:w-full text-xs lg:text-sm text-center text-balance">
              Un Album photos et vidéo HD offert
            </h2>
            <h2 className="lg:w-full text-xs lg:text-xs text-center text-balance">
              par passager
            </h2>
          </div>
          <div className="flex flex-col justify-between items-center mx-3 lg:mx-0 py-3 w-full lg:w-2/3 h-24 lg:h-28 lg:w-72 lg:min-w-72 lg:max-w-76 bg-white shadow-lg rounded-lg border">
            <i className="fas fa-mug-hot lg:text-2xl mb-3 ml-3" />
            <h2 className="lg:w-full text-xs lg:text-sm text-center text-balance">
              Une Boisson chaude offerte
            </h2>
            <h2 className="lg:w-full text-xs lg:text-xs text-center text-balance">
              par passager
            </h2>
          </div>
          <div className="flex flex-col justify-between items-center mx-3 lg:mx-0 py-3 w-full lg:w-2/3 h-24 lg:h-28 lg:w-72 lg:min-w-72 lg:max-w-76 bg-white shadow-lg rounded-lg border">
            <div className="mb-2">
              <img
                src="/image/icons/icon-tshirt.svg"
                alt="un t shirt offert"
                className="w-[20px] lg:w-[30px]"
              />
            </div>
            <h2 className="lg:w-full text-xs lg:text-sm text-center text-balance">
              Un t-shirt Addict Effet Pei offert
            </h2>
            <p className="text-xs">
              A l&apos;achat d&apos;un grande découverte
            </p>
          </div>
          <div className="flex flex-col justify-between items-center mx-3 lg:mx-0 py-3 w-full lg:w-2/3 h-24 lg:h-28 lg:w-72 lg:min-w-72 lg:max-w-76 bg-white shadow-lg rounded-lg border">
            <i className="fas fa-credit-card lg:text-2xl mb-3 lg:mb-0" />
            <h2 className="lg:w-full text-xs lg:text-sm text-center text-balance">
              Paiement en ligne rapide et sécurisé
            </h2>
            <PaymentMethods size="small" />
          </div>
          <div className="flex flex-col justify-start items-center mx-3 lg:mx-0 py-3 w-full lg:w-2/3 h-24 lg:h-28 lg:w-72 lg:min-w-72 lg:max-w-76 bg-white shadow-lg rounded-lg border">
            <img
              src="/image/icons/logo-mercedes-black.svg"
              alt="moyen de transport de qualité"
              className="w-[60px]"
            />
            <h2 className="lg:w-full text-xs lg:text-sm text-center text-balance">
              Moyen de transport neuf, climatisé et confortable
            </h2>
          </div>
          <div className="flex flex-col justify-start items-center mx-3 lg:mx-0 py-3 w-full lg:w-2/3 h-24 lg:h-28 lg:w-72 lg:min-w-72 lg:max-w-76 bg-white shadow-lg rounded-lg border">
            <div className="flex gap-4 mb-3">
              <i className="fas fa-snowflake lg:text-2xl" />
              <i className="fas fa-restroom lg:text-2xl" />
              <i className="fa-solid fa-martini-glass-citrus lg:text-2xl" />
            </div>
            <h2 className="lg:w-full text-xs lg:text-sm text-center text-balance">
              Batiment climatisé, avec toilettes privées et snacking / boissons
            </h2>
          </div>
        </div>
        <h2 className="w-full lg:w-4/12 text-xl 2xl:text-3xl font-playfair font-light text-center text-slate-500 mb-12">
          Faites votre séléction ci-dessous et laissez vous guider
        </h2>
      </div>
      <Items
        cart={cart}
        items={items}
        AddOrDeleteOrderItem={AddOrDeleteOrderItem}
        isPILoading={isPILoading}
      />
    </>
  );
}

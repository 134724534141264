/* eslint-disable react/prop-types */
import React from 'react';
import PaymentMethods from '../PaymentMethods';

export default function CourseFAQSection() {
  return (
    <div className="relative flex flex-col justify-start bg-transparent w-full h-full px-3 lg:px-6 overflow-hidden text-gray-500">
      <div className="relative z-10 flex flex-col justify-center items-center w-full lg:w-full">
        <h1 className="text-xl lg:text-4xl font-playfair uppercase mb-12 text-center">
          Questions fréquentes pour les formations
        </h1>
        <div className="flex flex-wrap justify-center items-stretch w-full lg:gap-10">
          <div className="flex flex-col w-full lg:w-5/12 justify-between items-start rounded-lg text-left px-6 text-xs lg:text-sm">
            <div className="mb-3">
              <h3 className="lg:text-base uppercase text-[#ceb071] mb-3">
                Comment s&apos;habiller ?
              </h3>
              Il est nécessaire d&apos;&ecirc;tre en tenu de sport. Des chaussures ferm&eacute;es
              (baskets, chaussures de randonn&eacute;s...), des lunettes de soleil
              et &ecirc;tre en short ou en pentalon.
            </div>
            <div className="mb-3">
              <h3 className="lg:text-base uppercase text-[#ceb071] mb-3">
                l&apos;âge, poids et condition physique ?
              </h3>
              <p className="lg:text-balance lg:mb-1">
                A partir de 18 ans. Le poids doit être entre 20 et 80kg. Autrement
                nous vous invitons à r&eacute;server par téléphone
                Vous devez avoir une bonne condition physique. Il faudra &ecirc;tre capable
                de courir et d&apos;avoir de l&apos;endurance.
              </p>
            </div>
            <div className="mb-3">
              <h3 className="lg:text-base uppercase text-[#ceb071] mb-3">
                Quels sont les conditions en cas d&apos;annulation d&apos;une formation ?
              </h3>
              En cas d&apos;annulation de notre part vous recevrez automatiquement un e-mail pour
              choisir un avoir ou un remboursement.
            </div>
          </div>
          <div className="flex flex-col w-full lg:w-5/12 justify-between items-start rounded-lg text-left px-6 text-xs lg:text-sm">
            <div className="mb-3">
              <h3 className="lg:text-base uppercase text-[#ceb071] mb-3">
                Quels sont les moyens de paiement acceptés ?
              </h3>
              Par carte bancaire en ligne
              <PaymentMethods />
            </div>
            <div className="mb-3">
              <h3 className="lg:text-base uppercase text-[#ceb071] mb-3">
                O&ugrave; se situent les formations ?
              </h3>
              Hors stages spécifiques, les formations ont lieu
              sur l&apos;île de la R&eacute;union &agrave; Saint-Leu
            </div>
            <div className="mb-3">
              <h3 className="lg:text-base uppercase text-[#ceb071] mb-3">
                Pratiquer en situation d&apos;handicap ?
              </h3>
              <div>
                Tout d&eacute;pendra de l&apos;handicap. C&apos;est pour cela que nous vous
                invitons à nous contacter par t&eacute;l&eacute;phone au
                <a href="tel:(0262)692256363" className="px-1">(0262)692-256363</a>
                ou par e-mail à l&apos;adresse suivante:
                <a href="mailto:info@addictparapente.com" className="px-1">info@addictparapente.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
